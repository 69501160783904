@import "@/styles/base-imports";

.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 4rem;
  align-items: center;

  .headline {
    font-family: $family-primary;
    margin-top: 4rem;
  }
}